@media only screen and (max-width: 768px){
  .navbar-style > .navbar-brand {
width: 55%;
font-size: 13px;
white-space: pre-line;
text-align: center;
padding-right: 26px;
}
  .logo.navbar-brand {
      width: 50px;
      overflow: hidden;
      margin-left: 10px;
      margin-right: 10px;
  }
  .logo img {
      width: 245px;
  }
  button.cart-style.btn.btn-primary.btn-sm {
      border-radius: 50%;
      padding: 8px;
      height: 42px;
      width: 42px;
  }
  li.cart-wrapper.nav-item {
      position: relative;
  }
  span.badge-style.badge.badge-secondary {
      position: absolute;
      border-radius: 50%;
      font-size: 10px;
      height: 20px;
      width: 20px;
      text-align: center;
      padding: 0;
      line-height: 19px;
      right: -4px;
      top:-4px;
      background: red;

  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  // this is a white icon with 50% opacity
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
  .custom-toggler.navbar-toggler {
    border-color: rgb(255,102,203);
  } 
ul.ml-auto.cart.navbar-nav {
position: absolute;
right: 5px;
top: 15px;
}
}
