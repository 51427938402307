button,
input,
optgroup,
select,
textarea {
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-normal;
}

a {
    color: $primary-color;

    &:hover,
    &:focus {
        color: $primary-color;
    }
}

h1,
.h1 {
    font-size: $font-size-h1;
    line-height: 1.15;
    margin-bottom: $margin-base-vertical * 2;
    font-weight: 700;

    small {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        opacity: .8;
    }
}

h2,
.h2 {
    font-size: $font-size-h2;
    margin-bottom: $margin-base-vertical * 2;
    line-height: 1.15;
    font-weight: 600;
}

h3,
.h3 {
    font-size: $font-size-h3;
    margin-bottom: $margin-base-vertical * 2;
    line-height: 1.4em;
    font-weight: 400;
}

h4,
.h4 {
    font-size: $font-size-h4;
    line-height: 1.45em;
    margin-top: $margin-base-vertical * 2;
    margin-bottom: $margin-base-vertical;
    font-weight: 400;

    &+.category,
    &.title+.category {
        margin-top: -10px;
    }
}

h5,
.h5 {
    font-size: $font-size-h5;
    line-height: 1.4em;
    margin-bottom: 15px;
}

h6,
.h6 {
    font-size: $font-size-h6;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

p {
    &.description {
        font-size: 1.14em;
    }
}

// i.fa{
//     font-size: 18px;
//     position: relative;
//     top: 1px;
// }

.title {
    font-weight: $font-weight-bold;

    &.title-up {
        text-transform: uppercase;

        a {
            color: $black-color;
            text-decoration: none;
        }
    }

    &+.category {
        margin-top: -10px;
    }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
    color: $dark-gray;
    font-weight: $font-weight-light;
}

.category,
.card-category {
    text-transform: capitalize;
    font-weight: $font-weight-normal;
    color: $dark-gray;
    font-size: $font-size-mini;
}

.card-category {
    font-size: $font-size-h6;
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
    color: $brand-primary !important;
}

.text-info,
a.text-info:focus,
a.text-info:hover {
    color: $brand-info !important;
}

.text-success,
a.text-success:focus,
a.text-success:hover {
    color: $brand-success !important;
}

.text-warning,
a.text-warning:focus,
a.text-warning:hover {
    color: $brand-warning !important;
}

.text-danger,
a.text-danger:focus,
a.text-danger:hover {
    color: $brand-danger !important;
}

.text-gray,
a.text-gray:focus,
a.text-gray:hover {
    color: $light-gray !important;
}


.blockquote {
    border-left: none;
    border: 1px solid $default-color;
    padding: 20px;
    font-size: $font-size-blockquote;
    line-height: 1.8;

    small {
        color: $default-color;
        font-size: $font-size-small;
        text-transform: uppercase;
    }

    &.blockquote-primary {
        border-color: $primary-color;
        color: $primary-color;

        small {
            color: $primary-color;
        }
    }

    &.blockquote-danger {
        border-color: $danger-color;
        color: $danger-color;

        small {
            color: $danger-color;
        }
    }

    &.blockquote-white {
        border-color: $opacity-8;
        color: $white-color;

        small {
            color: $opacity-8;
        }
    }
}