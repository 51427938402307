.content {
  background-color: #d4e4ffc3;
  color: rgb(9, 20, 63);
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  min-height: 900px;
  padding-top: 70px;
  padding-bottom: 50px;
  -webkit-overflow-scrolling: touch;
}

label{
  font-size: 16px !important;
}

.form-control {
  border: 1px solid black !important;
}

.lang-selector-div {
  width: 100%;
}